import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['toggle'];

    toggleTargetConnected(button) {
        const item = button.closest('a, span, strong');

        if (item.classList.contains('trail') || item.classList.contains('active')) {
            item.classList.add('show');
            button.setAttribute('aria-expanded', 'true');
        } else {
            button.setAttribute('aria-expanded', 'false');
        }
    }

    toggle(e) {
        e.preventDefault();

        const button = e.currentTarget;
        const item = button.closest('a, span, strong');
        const open = item.classList.contains('show');
        const closedLabel = button.dataset.ariaLabelClosed;
        const openedLabel = button.dataset.ariaLabelOpened;

        if (open) {
            item.classList.remove('show');
            button.setAttribute('aria-label', closedLabel);
            button.setAttribute('aria-expanded', 'false');
        } else {
            item.classList.add('show');
            button.setAttribute('aria-label', openedLabel);
            button.setAttribute('aria-expanded', 'true');
        }
    }
}
