import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        this.checkScrollPos = true;

        window.requestAnimationFrame(this._checkScrollPos.bind(this));
    }

    disconnect() {
        this.checkScrollPos = false;
    }

    _checkScrollPos() {
        if (window.scrollY >= window.innerHeight * 0.5) {
            this.element.classList.add('show');
        } else {
            this.element.classList.remove('show');
        }

        if (this.checkScrollPos) {
            window.requestAnimationFrame(this._checkScrollPos.bind(this));
        }
    }
}
