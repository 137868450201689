import GLightbox from 'glightbox';

const init = () => {
    document.querySelectorAll('a[data-lightbox]').forEach((element) => {
        if (!!element.dataset.lightbox) {
            element.setAttribute('data-gallery', element.dataset.lightbox);
        } else {
            element.setAttribute('data-gallery', crypto.randomUUID());
        }
    });

    GLightbox({
        selector: 'a[data-lightbox]'
    });
};

if ('loading' === document.readyState) {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

document.documentElement.addEventListener('turbo:render', init);
document.documentElement.addEventListener('turbo:frame-render', init);
