import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    toggle() {
        document.body.classList.toggle('show-menu');

        if (document.body.classList.contains('show-menu')) {
            document.body.classList.remove('close-menu');
        } else {
            document.body.classList.add('close-menu');
        }
    }

    close() {
        document.body.classList.remove('show-menu');
        document.body.classList.add('close-menu');
    }
}
