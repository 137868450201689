import Lenis from 'lenis';

const lenis = new Lenis();

(function lenisRaf(time) {
    lenis.raf(time);

    window.requestAnimationFrame(lenisRaf);

    return lenisRaf;
})();

document.documentElement.addEventListener('turbo:render', () => lenis.resize());
document.documentElement.addEventListener('turbo:frame-render', () => lenis.resize());
